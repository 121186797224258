
.mainMargin{
    margin-top: -120px;
}


.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}

.MarcellusFONT {
    font-family: 'Marcellus SC', serif;
}

.header-content {
    font-family: "Roboto", sans-serif;
    font-size: 5px;
    font-style: normal;
}

.FreudeStaysSileder{
    display: flex;
    justify-content: center;
    height: auto;
    width: 90%;
    margin-left: 70px;
}

/* HoverEffect IMG START*/

.HoverEffect:hover img {
    transform: scale(1.2);
    opacity: 0.5;
    transition: 5s;
    cursor: pointer;
}

.HoverEffect {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 1);
}

.HoverEffectHomeBG:hover img {
    transform: scale(1.1);
    opacity: 0.5;
    transition: 5s ease;
    cursor: pointer;
}

.HoverEffectHomeBG {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 2);
}

.HoverEffectVIDEO:hover video {
    transform: scale(1.1);
    opacity: 0.5;
    transition: all 5s;
    cursor: pointer;
}

.HoverEffectVIDEO {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 1);
}

.SECONDBMWIX:hover img {
    transform: scale(1.2);
    cursor: pointer;
}

/* HoverEffect IMG END*/


/* FONTFAMILY CONTENT START */

.fontFamilyHeader {
    font-family: 'Kanit', sans-serif;
    font-weight: 600;
}

.fontFamilyHeader1 {
    font-family: 'Kanit', sans-serif;
}

.SolutionDropDownFontFamily {
    font-family: 'Kanit', sans-serif;
}

.SolutionDropDownFontFamily1 {
    font-family: 'Kanit', sans-serif;
    line-height: 40px;
}

.SolutionDropDownFontFamily2 {
    font-family: 'Kanit', sans-serif;
    line-height: 23px;
}

.SolutionDropDown {
    font-family: 'Roboto', sans-serif;
}

.h1FontFamily {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 900;
}

.h1FontFamily1 {
    font-family: 'Ubuntu', sans-serif;
}

.h1FontFamilyLineHeight {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 900;
    line-height: 50px;
}

.h1FontFamilyLineHeight1 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 900;
    line-height: 60px;
}

/* FONTFAMILY CONTENT END */


/* BUTTON HOVER EFFECT START */

.READMOREBUTTON {
    transition: transform 1.6s;
    overflow: hidden;
    background-color: #F0EEED;
}

.READMOREBUTTON:hover {
    color: white;
    background-color: black;
}

.BMW_EXPLAINEDBUTTON {
    transition: transform 1.6s;
    overflow: hidden;
    background-color: white;
    width: 67%;
}

.BMW_EXPLAINEDBUTTON:hover {
    color: white;
    background-color: black;
    width: 67%;
}

.SHOWMOREBUTTON {
    transition: transform 1.6s;
    overflow: hidden;
    background-color: #FFFFFF;
    width: 350px;
}

.SHOWMOREBUTTON:hover {
    color: white;
    background-color: black;
}

.FOOTERLINK {
    color: #8e8e8e;
}

.FOOTERLINK:hover {
    color: white;
}

.HEADERLINK {
    color: white;
    font-size: 19px;
    padding: 5px;
    border-bottom: 2px solid white;
}

.HEADERLINK:hover {
    color: #084A9E;
    border-bottom: 2px solid #084A9E;
}

.HEADERLINK1 {
    color: white;
    border-bottom: 2px solid white;
}

.SECONDHEADERLINK {
    color: dark;
    border-bottom: 2px solid black;
}

.SECONDHEADERLINK:hover {
    color: #084A9E;
    border-bottom: 2px solid #084A9E;
}

.SECONDHEADERLINK1 {
    border-bottom: 1px solid black;
}

.MINIHEADER {
    color: #8e8e8e;
}

.MINIHEADER:hover {
    color: black;
    border-bottom: 5px solid black;
}

.BMWi {
    color: black;
    border-bottom: 5px solid black;
}

.BWIBORDERBOTTOMLINK {
    border-bottom: 1px solid rgb(38, 37, 37);
}

.BUTTONCAR {
    margin-bottom: -44px;
    margin-left: -36px;
}

/* BUTTON HOVER EFFECT END */

.BTNREADMORE1 {
    background-color: transparent;
    border: 2px solid black;
    height: 58px;
    color: black;
}

.BTNREADMORE1:hover {
    background-color: white;
    border: 2px solid white;
    height: 58px;
    color: black;
}

.BTNREADMORE {
    background-color: transparent;
    border: 2px solid white;
    height: 58px;
    color: white;
}

.BTNREADMORE:hover {
    background-color: white;
    border: 2px solid white;
    height: 58px;
    color: black;
}

.Img-Height {
    height: 100vh;
}

.bmw-films-size {
    font-size: 48px;
}

.the-icon {
    font-size: 20px;
}

.Learn-everything-size {
    font-size: 45px;
}

.BMW-your-country {
    font-size: 34px;
}

.ALL-BMW-MODELS {
    font-size: 83px;
}

.Find-your-BMW {
    font-size: 28px;
}

.circular-world-home-bg {
    height: 700px;
}

.reamaning-heading-common-size {
    font-size: 80px;
    color: black;
}

.text-color {
    color: black;
}

.img-size {
    width: 50%;
}

.shree-driving-pleasure {
    width: 30%;
    margin-bottom: 15px;
}

/* .child {
    background-image: url(../Image/Home_Bg.png);
    background-position: 50px -80px;
    background-size: cover;
    background-color: #1D1D1D;
    width: 95%;
    height: 400px;
    transition: 5s;
    cursor: pointer;
}

.child:hover {
    transform: scale(1.1);
    cursor: pointer;
    width: 95%;
    overflow: hidden;
} */

.common-font-size {
    font-size: 33px;
}

.home-bg-button {
    background-color: transparent;
    border: 2px solid white;
    margin-top: 20px;
    color: white;
    height: 55px;
}

.home-bg-button:hover {
    background-color: white;
    border: 2px solid white;
    margin-top: 20px;
    color: black;
    height: 55px;
}

.Audio-responisve-size {
    font-size: 25px;
    margin-top: 20px;
}

.home-main-tital-size {
    font-size: 50px;
    margin-top: 20px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 900;
    line-height: 60px;
    font-weight: bold;
}

.header-two-common-navbarr-size {
    font-size: 18px;
}

@media (max-width: 1200px) {
    .HEADERLINK {
        color: white;
        font-size: 18px;
        padding: 5px;
        border-bottom: 2px solid white;
    }

    .header-two-common-navbarr-size {
        font-size: 14px;
    }

}

@media (max-width: 767px) {
    .img-size {
        width: 100%;
    }

    .circular-world-home-bg {
        height: 600px;
    }

    .reamaning-heading-common-size {
        font-size: 60px;
        margin-top: 30px;
        color: white;
    }

    .Img-Height {
        height: 530px;
    }

    .bmw-films-size {
        font-size: 30px;
        font-weight: 500;
        line-height: 40px;
    }

    .the-icon {
        font-size: 18px;
    }

    .Learn-everything-size {
        font-size: 30px;
    }

    .BMW_EXPLAINEDBUTTON {
        width: 100%;
    }

    .text-color {
        color: white;
    }

    .common-font-size {
        font-size: 26px;
        padding: 14px;
    }

    .HEADERLINK {
        color: white;
        font-size: 15px;
        padding: 5px;
        border-bottom: 2px solid white;
    }

    .shree-driving-pleasure {
        width: 100%;
    }

    .child {
        background-image: url(../Image/Home_Bg.png);
        background-position: center;
        width: 100%;
    }

    .Audio-responisve-size {
        font-size: 15px;
    }

    .home-main-tital-size {
        font-size: 20px;
        font-weight: bold;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 900;
        margin-top: 10px;
        line-height: 30px;
    }

    .BMW-your-country {
        font-size: 20px;
        font-weight: 800;
    }

    .ALL-BMW-MODELS {
        font-size: 33px;
    }

    .Find-your-BMW {
        font-size: 18px;
    }

    .home-bg-button {
        margin-top: 10px;
    }

    .child {
        height: 450px;
    }

    .ContentHover {
        margin-top: 200px;
    }
}

@media (max-width: 1100px) {
    .HEADERLINK {
        color: white;
        font-size: 18px;
        padding: 5px;
        border-bottom: 2px solid white;
    }
}

/* SILDER CSS START */

@media (max-width: 767px) {
    .carousel-inner .carousel-item>div {
        display: none
    }

    .carousel-inner .carousel-item>div:first-child {
        display: block
    }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex
}

@media (min-width: 768px) {

    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
        transform: translateX(25%)
    }

    .carousel-inner .carousel-item-start.active,
    .carousel-inner .carousel-item-prev {
        transform: translateX(-25%)
    }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
    transform: translateX(0)
}

/* SILDER CSS END */

.HoverBackGroundImg {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 1);
    transition: all 6s;
}

.HoverBackGroundImg:hover {
    transform: scale(1.1);
    cursor: pointer;
}

@media screen and (max-width:990px){
    .mainMargin{
        margin-top: 0px;   
    }
   
    .nav-item{
        text-align: start;
    }
    .navbar{
        background-color: black;
    }
}