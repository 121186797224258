.Main-Containerr {
    padding: 36px;
    margin-bottom: 1px;
}

.VolumeIcon {
    padding: 16px;
    border: 2px solid black;
    border-radius: 50%;
}

audio {
    border: 2px solid black;
    border-radius: 50px;
}

audio::-webkit-media-controls-panel {
    background-color: #fff;
    color: #fff;
}

.ActivateButton {
    background-color: #fff;
    color: black;
    width: 180px;
    height: 50px;
    border: 2px solid black;
}

.ActivateButton:hover {
    background-color: #4d4d4d;
    color: white;
    width: 180px;
    height: 50px;
    border: 2px solid #4d4d4d;
}

.ReuseSize {
    margin-bottom: 20px;
}

.PRETREATMENTBOTTOM {
    margin-bottom: 50px;
}

.DISMANTLINGBOTTOM {
    margin-bottom: 30px;
}

.CommonHsix {
    font-size: 24px;
    color: #262626;
    font-weight: 600;
}

.CommonHsix2 {
    font-size: 24px;
    color: #262626;
    font-weight: 600;
}

.WeAreAble {
    font-size: 27.5px;
    line-height: 40px;
    font-weight: 900;
    text-align: start;
    letter-spacing: -.6px;
}

.WeAreAble2 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 900;
    text-align: start;
    letter-spacing: -.6px;
}

.CommonClient {
    font-size: 14px;
    color: #262626;
    line-height: 20px;
    font-weight: 600;
    text-align: start;
}

.WelcomeBMW {
    font-size: 5.959375vw;
    line-height: 4.859375vw;
    font-weight: 400;
    margin-bottom: 50px;
}

.RECYCLING {
    font-size: 5.559375vw;
    line-height: 4.859375vw;
    font-weight: 600;
}

.IMGHe {
    height: 100vh;
}

.Author {
    font-size: 20px;
    color: #262626;
    font-weight: 600;
}

.AfterlifeBmwCarBottomButton button {
    padding: 10px 18px;
    color: #1c69d4;
    width: 175px;
    font-weight: 400;
    overflow: hidden;
    line-height: 30px;
    font-size: 18px;
    border: none;
    background-size: 200% 200%;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.AfterlifeBmwCarBottomButton button:hover {
    box-shadow: inset 0px -60px 0 0 #dbe6f8;
}

@media (max-width: 1170px) {
    .AfterlifeBmwCarBottomButton button {
        padding: 10px 7px;
        color: #1c69d4;
        width: 100%;
        font-weight: 400;
        overflow: hidden;
        line-height: 30px;
        font-size: 15px;
        border: none;
        background-size: 200% 200%;
        transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    }
}

@media (max-width: 990px) {
    .Main-Containerr {
        padding: 40px;
        margin-bottom: 5px;
    }
}

@media (max-width: 767px) {
    .Main-Containerr {
        padding: 30px;
    }

    .ActivateButton {
        width: 100%;
    }

    .CommonHsix {
        font-size: 19px;
        color: #262626;
        font-weight: 400;
    }

    .CommonHsix2 {
        font-size: 15px;
        color: #262626;
        font-weight: 400;
    }

    .ReuseSize {
        margin-bottom: 40px;
    }

    .WeAreAble {
        text-align: center;
        font-size: 25.6px;
        line-height: 38px;
        font-weight: 900;
    }

    .WeAreAble2 {
        text-align: center;
        font-size: 26px;
        line-height: 38px;
        font-weight: 600;
    }

    .CommonClient {
        font-size: 15px;
        color: #262626;
        line-height: 20px;
        font-weight: 600;
        text-align: center;
    }

    .WelcomeBMW {
        font-size: 8.959375vw;
        line-height: 9.359375vw;
        font-weight: 400;
        margin-bottom: 80px;
    }

    .PRETREATMENTBOTTOM {
        margin-bottom: 70px;
    }

    .DISMANTLINGBOTTOM {
        margin-bottom: 35px;
    }

    .RECYCLING {
        font-size: 9.559375vw;
        line-height: 9.859375vw;
        font-weight: 500;
    }

    .IMGHe {
        height: 300px;
    }

    .Author {
        font-size: 18px;
        color: #262626;
        font-weight: 600;
    }

}