.Thei4car img {
    width: 91%;
    height: auto;
    padding: 50px;
}

.Main-Container-Fluid {
    padding: 100px;
}

.NowThen {
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 5.559375vw;
}

.FREUDE-STAYS-FOREVER {
    font-weight: 400;
    font-size: 5.559375vw;
}

.FredueStaysVideoSize {
    width: 100%;
    height: 600px;
    object-fit: fill;
}

.SilderReadMore {
    padding: 50px;
    margin-bottom: 0px;
}

.Hash {
    color: #999;
    font-size: 18px;
}

.Hash:hover {
    color: #1c69d4;
}

.BottomButton button {
    padding: 10px 18px;
    color: #1c69d4;
    font-weight: 400;
    overflow: hidden;
    line-height: 30px;
    font-size: 18px;
    border: none;
    background-size: 200% 200%;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.BottomButton button:hover {
    box-shadow: inset 0px -60px 0 0 #dbe6f8;
}


@media (max-width: 1286px) {
    .Thei4car img {
        width: 100%;
        height: auto;
        padding: 0px;
        margin-bottom: 40px;
    }

    .Main-Container-Fluid {
        padding: 0px;
    }
}

@media (max-width: 1170px) {
    .BottomButton button {
        padding: 7px 7px;
        color: #1c69d4;
        font-weight: 400;
        overflow: hidden;
        line-height: 30px;
        font-size: 17px;
        border: none;
        /* box-shadow: inset 0 0 0 0 #54b3d6; */
        background-size: 200% 200%;
        transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    }
}

@media (max-width: 990px) {
    .NowThen {
        margin-bottom: 60px;
        margin-top: 30px;
        font-size: 70px;
    }

    .SilderReadMore {
        padding: 0px;
        margin-bottom: 70px;
    }
}

@media (max-width: 767px) {
    .FREUDE-STAYS-FOREVER {
        font-weight: 400;
        font-size: 9.959375vw;
    }

    .FredueStaysVideoSize {
        width: 100%;
        height: 300px;
        object-fit: fill;
    }

    .NowThen {
        margin-bottom: 60px;
        margin-top: 80px;
        font-size: 45px;
    }

    .BottomButton button {
        padding: 10px 18px;
        color: #1c69d4;
        font-weight: 400;
        overflow: hidden;
        line-height: 30px;
        font-size: 19px;
        border: none;
        /* box-shadow: inset 0 0 0 0 #54b3d6; */
        background-size: 200% 200%;
        transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    }
}